@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;800&display=swap');



video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100px;
  height: 100px;
  min-width: 100%;
  min-height: 100%;
  z-index: -1;
  object-position: center;
  object-fit: cover;
  opacity: 0.4;
}
.dark-overlay {
  background: rgb(1,1,1);
  background: linear-gradient(180deg, rgba(1,1,1,0) 70%, rgba(0,0,0,1) 90%);
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    } 

body{
  margin:0px;
  padding: 0px;
 
  display:block;
  background-color: #000000;
  font-family: 'Montserrat', sans-serif;
 
}
*{
  box-sizing: border-box;
}
ul{
  list-style: none;
}
a{
  text-decoration: none;
}
button{
  outline: none;
  border: none;
}
input{
  outline: none;
  border: none;
}
.logo img{
  width: 120px;
}
#main{

  width:100%;
  height:850px;
  position: relative;
  background-repeat: no-repeat;
  /* background-size: cover;
 background-image: url("images/background.png"); */
}
nav{
display: flex;
justify-content: space-around;
align-items: center;
box-shadow: 5px 10px 30px rgba(0, 0, 0, 0.336);
position: fixed;
left: 0;
top: 0;
width:100%;
z-index: 3;
background-color: rgba(0, 0, 0, 0.736);
}
nav ul{
display: flex;
}
.active{
  background-color: #EB004D;
  background: #EB004D;
  color:#000000;
box-shadow: 5px 10px 30px rgba(237,21,115,0.4);
transition: all ease 0.2s;
}
nav ul li a{
  font-family: 'Montserrat', sans-serif;
height:40px;
line-height: 43px;
margin: 3px;
padding: 0px 22px;
display: flex;
font-size: 0.8rem;
text-transform: uppercase;
font-weight: 500;
color:#ffffff;
  letter-spacing: 1px;
  border-radius: 3px;
  transition: 0.2s ease-in-out;
}
.hey{
  font-family: 'Montserrat', sans-serif;
color:#ffffff;
font-weight: 500;
font-size: 0.9rem;
border-bottom: 2px solid #000266;
}
nav ul li a:hover{
  background-color: #EB004D;
  background: linear-gradient(130deg, #EB004D 5%, #EB004D 90%);;
  color:#000000;
box-shadow: 5px 10px 30px rgba(237,21,115,0.4);
transition: all ease 0.2s;
}
nav .menu-btn,
.menu-icon{
  display:none;
;
}
div .language-btn-container{
  align-items: center;


}
.name{
  font-family: 'Montserrat', sans-serif;
width: 500px;
position: absolute;
  left: 50%;
  text-align: center;
top: 50%;
transform: translate(-50%,-50%);
z-index:2;
}
.name span{
 
  color: #EB004D;
  border-bottom: 2px solid #EB004D;
}
.name .details{
  color: #ffffff;
  font-weight:400;
}
.name h1{
  font-family: 'Montserrat', sans-serif;
font-size: 56px;
margin:0px;
font-weight: 800;
letter-spacing: 3px;
color:#ffffff;
}
.header-btns{
  display: flex;
  margin-top: 40px;
  width:100%;
  
  justify-content: center;
}
.cv-btn{
width:170px;
height: 40px;
display: flex;
justify-content: center;
  align-items: center;
  background-color: #EB004D;
  background: linear-gradient(130deg, #EB004D 5%, #EB004D 100%);;
  box-shadow: 5px 10px 30px rgba(255, 2, 2, 0.137);
  border-radius: 10px;
color:#FFFFFF;
font-weight:600;
}


.cv-btn1{
  font-size: 14px;
width:100px;
height: 40px;
display: flex;
justify-content: center;
  align-items: center;
  background: transparent;
  border: 1px solid #ffffff;
  color:#ffffff;
  border-radius: 10px;
  margin-left: 20px;
}

.cv-btn2{
  font-size: 14px;
width:100px;
height: 30px;
display: flex;
justify-content: center;
  align-items: center;
  background: transparent;
  border: 1px solid transparent;
  color:#ffffff;
  border-radius: 10px;
  margin-left: 20px;
}

.cv-btn3{
  width:90%;
  height: 60px;
  display: flex;
  justify-content: center;
    align-items: center;
    background-color: #000000;
   
    box-shadow: 5px 10px 30px rgba(255, 2, 2, 0.137);
    border-radius: 10px;
  color:#FFFFFF;
  font-weight:600;
  }
  .cv-btn4{
    font-size: 14px;
  width:auto;
  height: 30px;
  display: flex;
  justify-content: center;
    align-items: center;
    background: transparent;
    border: 1px solid transparent;
    color:#ffffff;
    border-radius: 10px;
    margin-left: 20px;
  }

.cv-btn:hover{
background-color: #ffffff;
  transition: all ease 0.5s;
  color: #000266;
  cursor: pointer;
}
.cv-btn1:hover{
background-color: #ffffff;
  transition: all ease 0.5s;
  color: #000266;
}
.cv-btn2:hover{
  background-color: #ffffff;
    transition: all ease 0.5s;
    color: #000266;
  }
  .cv-btn3:hover{
   
      transition: all ease 0.5s;
      background-color: #EB004D;
      background: linear-gradient(130deg, #EB004D 5%, #EB004D 100%);;
    
    }
    
    .cv-btn4:hover{
      background-color: #ffffff;
        transition: all ease 0.5s;
        color: #000266;
      }
.arrow{
  align-self: end;
  width: 50%;
  height: 15%;
  margin-bottom: 4em;
  position: absolute;
  bottom:0px;
  border-right: 2px solid  #EB004D;
  animation: arrow-animation ease 1.5s;

}
.arrow::after{
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 11px 11px 0px 11px;
  border-color: #EB004D transparent transparent transparent;
  right: -0.7em;
  bottom: -2px;
}
@keyframes arrow-animation{
  0%{
      bottom: 70px;
      opacity: 0.2;
  }
  100%{
      bottom: 5px;
      opacity: 1;
  }
}
.f-heading h1{
  color:#FFFFFF;
  font-size: 2.5rem;
  margin: 0px;
  padding: 0px;
  

}
.f-heading p{
  color:rgba(247, 247, 247, 0.678);
  font-size: 1rem;
  margin-top: 10px;
  padding: 0px;
}
.f-heading{
  text-align: center;
  margin-top: 60px;
  margin-bottom: -120px;
  height:50%;
}
#features{
    margin-top:140px ;
width:100%;
height: 120vh;
background-color:#000000;
box-sizing: border-box;
display: flex;
align-items: center;
justify-content: space-between;
padding: 50px 5% 0px 5%;
position: relative;
}

.features-text{
  width: 45%;
  color: #fff;
  list-style: circle;
}
.features-text h2{
font-size: 2rem;
color:#EB004D;
font-weight: 400;
}
.features-text h2{
margin: 0px;
padding: 0px;
}
.features-text p{
font-size: 1.2rem;
color:#b4b4b4;

}
.features-text a{
  margin-top: 20px;
  padding: 10px;
  width: 110px;
  height: 40px;
border-radius: 10px;
border: none;
  outline: none;
  color: #FFFFFF;
  font-family: 'Montserrat', sans-serif;
  font-size: 1.2rem;
  font-weight:600;
  background-color: #EB004D;
  background: linear-gradient(130deg, #EB004D 5%, #EB004D 90%);;
}
.features-text button:hover{
background-color: #ffffff;
  transition: all ease 0.3s;
  color: #000266;
}
.features-model{
  width: 50%;
  
  
}
.features-model img{
margin-left: 100px;
}

#services{
  margin-top: 120px;
  width:100%;
  height:100vh;
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 40px ;
  
}

.s-heading h1{
  color:#FFFFFF;
  font-size: 3rem;
  margin: 0px;
  padding: 0px;

}
.s-heading p{
  color:rgba(247, 247, 247, 0.678);
  font-size: 1rem;
  margin: 0px;
  padding: 0px;
}
.s-heading{
  text-align: center;
  margin: 20px 0px;
}
.s-box{
  background-color: #ffffff;
  width:350px;
  height: 470px;
  margin: 20px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 2px 2px 12px rgba(0,0,0,0.1);
  position: relative;

  
  
 
}
.b-container{
  display: flex;
  justify-content: center;
  align-content: center;
  height: 120vh;


}
.s-b-img{
  width: 100%;
  height: 100%;
  
  


}
.s-b-img img{
  width:100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  

}
.s-b-text{
  width: 100%;
  height: 100%;
  background: rgb(255,255,255);
background: linear-gradient(180deg, rgba(255,255,255,0) 20%, rgba(255,255,255,0.8) 90%);
  display: flex;
  backdrop-filter: blur(0px);
  position:absolute;
  z-index: 2;


  justify-content: flex-end;
  padding-bottom: 20px;
  align-items: center;
  text-align: left;
  flex-direction: column;

}
.s-b-text p{
  margin: 0px;
  color:transparent;
  font-size: 1.1rem;
  font-family: 'Montserrat', sans-serif;
  display: block;
  display: -webkit-box;
  max-width: 85%;
  margin-top: 25px;
  height: auto;

  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 400;
}
.s-b-text:hover{
  color:#000266;
  backdrop-filter: blur(4px);
}

.s-b-text:hover p{
  color:#000000;
}
.s-b-text a{
  margin-top:15px ;
}
.s-type{
  font-family: 'Montserrat', sans-serif;
  color:#000266;
  background-color: #ffffff;
  padding: 10px 15px;
  position: absolute;
  top: 0px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 18px;
  border-radius: 0px 0px 10px 10px;
  box-shadow: 2px 2px 12px rgba(0,0,0,0.2);
}
.s-box:hover{
  transform:translateY(-20px);
  transition: all ease 0.3s;
  box-shadow: 2px 2px 50px rgba(255,254,254,0.25);


}

#subscribe{

background-color: #EB004D;

flex-direction: column;
justify-content: center;
align-items: center;
 
  margin-top: 80px;
  padding: 30px 0px;
  
}
#subscribe form{
  align-items:center;
  display:flex;
  flex-direction: column;

}
#subscribe h3{
font-size:2.5rem;
margin:20px;
color: #000000;

text-align: center;
}
.subscribe-input{
  width:70%;
height:45px;
background-color:#FFFFFF;
display:flex;
justify-content: center;
border-radius: 10px;
box-shadow: 2px 2px 30px rgba(0,0,0,0.15);
margin-bottom: 10px;
}

.subscribe-input2{
 
  height:50px;
  display:flex;
  justify-content: center;
  border-radius: 50px;
  
  }
  .subscribe-input-area{
    width:70%;
    height:100px;
    background-color:#FFFFFF;
    display:flex;
    justify-content: center;
    border-radius: 10px;
    box-shadow: 2px 2px 30px rgba(0,0,0,0.15);
    margin-bottom: 10px;
    
    
    }
  
.subscribe-input input{
width:100%;
background-color: transparent;
border:none;
outline: none;
text-align: center;
color:#242425;
text-align: left;
  
  margin: 15px;
}

.subscribe-input-area input{
  width:100%;
  background-color: transparent;
  border:none;
  outline: none;
  text-align: left;
  align-self: flex-start;
  margin: 15px;
  color:#242425;
  }

.subscribe-input a{
width:200px;
height:35px;
background-color: #EB004D;
color:#000000;
display: flex;
font-size: 0.9rem;
justify-content: center;
align-items: center;
margin: auto 10px;
border-radius: 20px;
font-weight: 500;
transition: 0.4s ease-in;
box-shadow: 2px 2px 30px rgba(0,0,0,0.15);
}

.subscribe-input2 button{
  width:200px;
  height:35px;
  background-color: #000000;
  color:#EB004D;
  display: flex;
  font-size: 0.9rem;
  justify-content: center;
  align-items: center;
  margin: auto 10px;
  border-radius: 10px;
  font-weight: 600;
  transition: 0.4s ease-in;
 
  }
.subscribe-input a:hover{
  background-color:#000000;
color:#EB004D;
}
.subscribe-input2 button:hover{
  background-color:#000000;
  color:#FFFFFF;
  cursor: pointer;
  box-shadow: 2px 2px 30px rgba(0,0,0,0.85);
  
}




.footer{
  display:flex;
  background-color: #000000;
  

  padding: 10px;
  justify-content: space-between;
  align-items: center;
 
   
  
  }

.footer p{
  color:white;
  font-size: small;
}

.footer-left{
  flex:1;



}
.footer-right{
  display: flex;
 
  flex-direction: row;

  

}
@media(max-width:1190px){
#main{
  background-size: 1190px !important;
  
}
.name{
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
}
.header-btns{
  width: 100%;
  
  justify-content: center;
}

.features-model img{
  height:800px;
  }
  #services{
  height:auto;
  }
  .b-container{
  flex-wrap: wrap;
}
  .s-box{
  flex-grow: 1;
}
.s-b-img img{
  object-fit:cover;
}

}

@media(max-width:970px){
  #main{
    background-size: 970px !important;
  }
  #services{
    margin-top: 60px;
  }

.features-model{
  width:100%;

}
.features-model img{
  margin-left: 0;
  margin-bottom: 100px;
  height:400px
}
.s-heading h1{
  font-size: 2.5rem;
  color: #EB004D;
}
.features-text h2{
  font-weight: 600;
}
.b-container{
  margin-top: 30px;
}
#subscribe h3{
  font-size: 2.5rem;
}
#features{
  margin-top: 300px;
  height:auto;
  justify-content: center;
  padding-top: 0px;
  font-size: 2rem;
  text-align: center;
  flex-direction: column;
}
#services{
  height:auto;
  padding-bottom: 400px;
  
 

}

.features-text{
  width: auto;
}
.features-text h1{
  font-size:4rem;
  }

}


@media(max-width:600px){
  #main{
    background-size: 600px !important;
    
  }

.footer-right{
  flex-direction: column;
}
.name{
  width:90%;
  align-items: center;
  justify-content: center;
  }

}


@media(max-width:1100px){.menu-icon{
  display:block;}
      nav{
          justify-content: space-between;
          height: 65px;
          padding: 0px 30px;
      }
      .logo img{
          width:70px;
      }
      .header-btns{
          margin: 0;
      }
      .menu{
          display:none;
          position:absolute;
          top:65px;
          left:0px;
          background-color:#000000;
          border-bottom:4px solid #EB004D;
          width:100%;
          padding:0px;
          margin:0px;
      }
      .menu li{
          width:100%;
      }
      nav .menu li a{
          width:100%;
          height:40px;
          justify-content: center;
          align-items: center;
          margin:0px;
          padding: 25px;
          border:1px solid rgba(158, 155, 0, 0.03);
      }
      nav .menu-icon{
          cursor:pointer;
          float:right;
          padding:28px 20px;
          position:absolute;
          user-select: none;
          left: 0;
          right:0;
          margin-left: auto;
          margin-right: auto;

          position: absolute;
          width: 40%;
          display: flex;
          justify-content: center;
          align-items: center;
          align-content: center;
          
      }
      nav .menu-icon .nav-icon{
        background-color: #EB004D;
          position: absolute;
          
          display:block;
          height:2px;
          transition: background 0.2s ease-out;
          width:20px;
      }
      nav .menu-icon .nav-icon:before,
      nav .menu-icon .nav-icon:after{
        background-color: #EB004D;
          content:'';
          display:block;
          height:100%;
          position:absolute;
          transition:all ease-out 0.2s;
          width:100%;
      }
      nav .menu-icon .nav-icon:before{
          top:5px;
      }
      nav .menu-icon .nav-icon:after{
          top:-5px;
      }
      nav .menu-btn:checked ~ .menu-icon .nav-icon{
          background:transparent;
      }
      nav .menu-btn:checked ~ .menu-icon .nav-icon:before{
          transform: rotate(-45deg);
          top:0;
      }
      nav .menu-btn:checked ~ .menu-icon .nav-icon:after{
          transform: rotate(45deg);
          top:0;
      }
      nav .menu-btn{
          display:none;
      }
      nav .menu-btn:checked ~ .menu{
          display:block;
      }
      .f-heading{
        padding-left:25px;
        padding-right:25px
      }
    
    }
      
      @media(max-width:400px){
          /* .s-box{
              width: 100%;
              height:400px;
          } */
          .scroll{
            background-color: red;
          }
          /* nav .menu-icon{
            position: absolute;
            left: 42%;
        } */
      }
      @media(max-width:376px){
   
        
        }